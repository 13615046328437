import { Tag, Modal } from 'antd'

import dayjs from 'dayjs';
import '../Styles/Utils.css'


/**
 * @param {*} estatus
 * @description Renderiza el Tag con el estatus de la inversion
 */
const renderEstatusCliente = ({ estatus = 0 }) => {

    let steps = {
        0: <Tag className="tag-estatus cancelado">Cancelado</Tag>,
        1: <Tag className="tag-estatus registrado">Registrado</Tag>,
        2: <Tag className="tag-estatus contactado">Contactado</Tag>,
        3: <Tag className="tag-estatus compro">Otro</Tag>,
        4: <Tag className="tag-estatus pagado">Pagado</Tag>,

    }

    return estatus != undefined ? steps[estatus] : 'N/A'

}

/**
 * @param {*} estatus
 * @description Renderiza el Tag con el estatus de la inversion
 */
const renderEstatusQuotation = (estatus) => {

    let steps = {
        0: <Tag color="#0089ED">Pending</Tag>,
        1: <Tag color="#36F905">Accepted</Tag>,
        2: <Tag color="#EFC904">Refused</Tag>,
        3: <Tag color="#FF0000">Cancelled</Tag>

    }

    return estatus != undefined ? steps[estatus] : 'N/A'

}

const renderEstatusVenta = (estatus, obj = false) => {

    let steps = {
        0: <Tag color="#94979c">Pending</Tag>,
        1: <Tag color="#c5e1c7">Order Received</Tag>,
        2: <Tag color="#5465FF">Cleaning in progress</Tag>,
        3: <Tag color="#F2802E">Final Detailing Touches</Tag>,
        4: <Tag color="#c5e1c7">Order Ready</Tag>,
        5: <Tag color="#94979c">Order Completed</Tag>,
        6: <Tag color="#94979c">Pending-Paid</Tag>,
        8: <Tag color="#e33814">Almost Ready</Tag>,
    }

    let status = {
        0: { color: "#94979c", name: 'Pending' },
        1: { color: "#c5e1c7", name: 'Order Received' },
        2: { color: "#5465FF", name: 'Cleaning in progress' },
        3: { color: "#F2802E", name: 'Final Detailing Touches' },
        4: { color: "#c5e1c7", name: 'Order Ready' },
        5: { color: "#94979c", name: 'Order Completed' },
        6: { color: "#94979c", name: 'Pending-Paid' },
        8: { color: "#e33814", name: 'Almost Ready' },
    }

    if (obj) return status[estatus] ?? { color: "#979799", name: "N/A" }

    return estatus != undefined ? steps[estatus] : 'N/A'

}

const renderSource = (from_terminal) => {

    let steps = {
        0: <Tag color="#ff4747">Online Purchase</Tag>,
        1: <Tag color="#c7d8e1">In-Store Purchase</Tag>,
    }

    return (from_terminal) ? steps[1] : steps[0]

}

const MoneyFormat = (value = 0) => {
    const money = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    })

    return money.format(value)
}

const FolioFormat = (value, size = 6) => {
    return "#" + Number(value).toString().padStart(size, '0')
}

/**
 * 
 * @param {*} response 
 * @param {String} si_falla_text 
 * @returns {String}
 */
const getResponseError = (response, si_falla_text) => {

    if (typeof (response?.data) === "string")
        return response.data

    if (typeof (response?.data?.message) === "string")
        return response.data.message

    if (typeof (response?.data?.message?.data) === "string")
        return response.data.message.data

    return si_falla_text;
}

const getResponseErrorAPI = (response, si_falla_text) => {

    if ((Array.isArray(response?.data) && response.data?.length > 0))
        return Modal.info({
            title: 'A error ocurred in process',
            content: (
                response.data.map(error => <div>
                    <p>{error.message}</p>
                </div>)

            ),
            onOk() { },
        })

    if (Array.isArray(response?.data?.errores) && response.data?.errores.length > 0)
        return Modal.info({
            title: 'A error ocurred in process',
            content: (
                response.data.errores.map(error => <div>
                    <p>{error.message}</p>
                </div>)

            ),
            onOk() { },
        })

    return si_falla_text;
}

const renderImage = (id, images, model) => {
    if (images && images.length > 0)
        return `${process.env.REACT_APP_WEB_SERVICE}/${model}/${id}/?imagen=${images[0]?.file}`
    return `${process.env.REACT_APP_WEB_SERVICE}/images/default.jpg`
}

const RenderSlot = (value) => {
    switch (value) {
        case 1:
            return "9:00 am - 11:00 am"
        case 2:
            return "11:00 am - 2:00pm"
        case 3:
            return "03:00 pm - 7:00pm"
        default:
            return 'N/A'
            break;
    }
}


const tiposServiciosVenta = (servicios) => {
    let limpieza = servicios.find( e => e.tipo === 0 )
    let reparacion = servicios.find( e => e.tipo === 1 )

    return <>
        {/* {limpieza ? <Tag color="yellow">Cleanning</Tag> : null} */}
        {reparacion ? <Tag color="red">Repair</Tag> : null}
    </>

}

const paymentStatus = (square_payment) => {
    

    return <>
        {square_payment ? <Tag color="green">Paid</Tag> : <Tag color="orange">Pending</Tag>}
    </>

}

/**
 * Método para generar una etiqueta (Tag) con un color y texto personalizados según la diferencia de días,
 * excluyendo sábados, domingos y fechas específicas, entre una fecha dada (fecha de creación) y la fecha actual.
 *
 * @param {string} fecha - La fecha de creación a comparar con la fecha actual.
 * @param {number} status - El estado del elemento; si es 5, no se genera el tag.
 * @returns {JSX.Element | string} - Retorna un componente Tag con el color y texto correspondientes,
 *                                   o una cadena vacía si el estado es 5.
 */
const tagDiasCreacion = (fecha, status) => {
    // Retorna vacío si el estado es 5, lo que significa que no se debe generar un tag en este caso.
    if (status === 5) return "";

    const fecha_hoy = dayjs();
    const fecha_creacion = dayjs(fecha);

    // Fechas específicas que no deben ser tomadas en cuenta
    const fechasExcluidas = [
        dayjs('2024-11-11'), // Nov 11, 2024
        dayjs('2024-11-28'), // Nov 28, 2024
        dayjs('2024-12-25'), // Dec 25, 2024
        dayjs('2024-12-31'), // Dec 31, 2024
        dayjs('2025-01-01'), // Jan 1, 2025
        dayjs('2025-01-20')  // Jan 20, 2025
    ];

    // Variable para contar los días efectivos (sin contar sábados, domingos y fechas específicas)
    let diasValidos = 0;

    // Itera desde la fecha de creación hasta la fecha actual
    for (let date = fecha_creacion; date.isBefore(fecha_hoy) || date.isSame(fecha_hoy, 'day'); date = date.add(1, 'day')) {
        // Si es sábado (6) o domingo (0), se salta
        if (date.day() === 6 || date.day() === 0) {
            continue;
        }

        // Si la fecha actual está en la lista de fechas excluidas, se salta
        if (fechasExcluidas.some(fechaExcluida => fechaExcluida.isSame(date, 'day'))) {
            continue;
        }

        // Si no es fin de semana ni fecha excluida, cuenta como día válido
        diasValidos++;
    }

    // Inicializa el objeto que contendrá las propiedades del tag (color, estilo y texto)
    let tagProps = {};

    // Lógica para determinar el contenido y estilo del tag basado en los días válidos
    if (diasValidos === 0) {
        if(fecha_hoy.format("DD") !== fecha_creacion.format("DD")){
            tagProps = { color: "#99f547", style: { color: "black" }, text: "Yesterday" };
        } else {
            tagProps = { color: "#99f547", style: { color: "black" }, text: "Today" };
        }
    } else if (diasValidos <= 2) {
        tagProps = { color: "#99f547", style: { color: "black" }, text: `${diasValidos} Days` };
    } else if (diasValidos <= 4) {
        tagProps = { color: "#f0f547", style: { color: "black" }, text: `${diasValidos} Days` };
    } else if (diasValidos <= 365) {
        tagProps = { color: "#f54c47", text: `${diasValidos} Days` };
    } else {
        tagProps = { color: "black", text: "+1 year ago" };
    }

    // Retorna un componente Tag con las propiedades calculadas (color, estilo y texto)
    return <Tag color={tagProps.color} style={tagProps.style}>{tagProps.text}</Tag>;
};


export {
    renderEstatusCliente,
    renderEstatusQuotation,
    renderEstatusVenta,
    getResponseError,
    getResponseErrorAPI,
    renderImage,
    MoneyFormat,
    FolioFormat,
    RenderSlot,
    renderSource,
    tiposServiciosVenta,
    paymentStatus,
    tagDiasCreacion
}