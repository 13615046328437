
import React, { Component, createRef } from 'react';
import { Button, Space, Image, Typography, InputNumber, Table, Form, message, Modal, Row, Col, Select, Spin, Input, Popconfirm } from 'antd';
import axios from 'axios';


import Logged, { User } from "../../../Hooks/Logged";
import usePermissions from '../../../Hooks/usePermissions';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import ModalProductos from './ModalProductos';
import { renderEstatusVenta } from '../../Utils';
import SelectClientes from '../../Widgets/Inputs/SelectClientes'
import ModalVentas from './ModalVentas';
import ModalServicios from './ModalServicios';
import ModalShipping from './ModalShipping';

const Decimal = require('decimal.js');
const { Text, Title } = Typography
const { Option } = Select;

/**
 *
 *
 * @export
 * @class TableOrdenCompra
 * @extends {Component}
 * 
 * @description Permite gestionar la tabla de productos de la orden de Compra
 */
class TableOrdenCompra extends Component {

	/**
	 *
	 *
	 * @memberof TableOrdenCompra
	 * @state orden Almacen la orden Actual
	 * @state dataSource Lista de elementos de la tabla
	 * @state visibleModalAgregarProductos Permite agregar y eliminar productos. 
	 */
	state = {
		orden: {},
		dataSource: [],
		visibleModalAgregarProductos: false,
		loading: false,
		loadingClientInfo: false,
		visibleModalAgregarEnvio: false, 
		disableEdit: false,
		detalle: null
	}

	/**
	 *
	 *
	 * @memberof TableOrdenCompra
	 * @description Obtenemos la orden de compra
	 */
	componentDidMount() {
		this.getVenta()
	}

	/**
   *
   *
   * @memberof TableOrdenCompra
   * @description Formatea el arreglo a un objeto de indices para que pueda ser interprestado por el formulario.
   */
	setOrdenesDetalles = (arreglo = []) => {
		let values = {}
		for (let index = 0; index < arreglo.length; index++) {
			const data_orden_detalle = arreglo[index]
			values[index] = data_orden_detalle
		}
		return values
	}

	/**
	 *
	 *
	 * @memberof TableOrdenCompra
	 * @description Obtenemos la orden de compra
	 */
	getVenta = () => {
		this.setState({ loading: true })
		axios.get('/venta/' + this.props.venta_id)
		.then(({ data }) => {
			console.log("data", data.detalles);
			this.formTableOrdenCompra.current.setFieldsValue({
				...data,
				status: data.status,
				cliente_id: data.cliente_id?._id ? {
					value: data.cliente_id?._id,
					label: `${data.cliente_id?.nombre ?? '-'} ${data?.cliente_id?.apellidos}`
				} : null,
				email: data.cliente_id?.email,
				telefono: data.cliente_id?.telefono,
				apellidos: data.cliente_id?.apellidos,
				zip_code: data.zip_code,
				discount:{
					amount: data.discount,
					type_discount: data.type_discount
				}

			})
			this.setState({
				total:  data.total,
				dataSource: data.detalles,
				disableEdit: data.square_payment ?? false,
				type_discount: data.type_discount,
				type_discount_editable: data.type_discount
			})
		})
		.catch(e => {
			console.log('error', e)
			message.error("Error getting the sale")
		}).finally(()=>this.setState({loading: false}))
	}

	handleClientSelect = (client_id) => {
		console.log("client_id", client_id);
		if (client_id === 'new' || client_id == undefined || client_id == null) return
		this.setState({ loadingClientInfo: true })
		axios.get('/clientes/' + client_id).then(response => {
			console.log(response)
			this.formTableOrdenCompra.current.setFieldsValue({
				apellidos: response.data.apellidos,
				email: response.data.email,
				telefono: response.data.telefono
			})
		}).catch(error => {
			message.error("There's been an error loading the values")
			console.log(error)
		}).finally(() => this.setState({ loadingClientInfo: false }))
	}

	/**
	 *
	 * @memberof TableCompra
	 * @description Elimina un item de carrito, local y en square, si se eliminan todos los items se elimina la venta
	 */
	deleteItem = (id) => {
		this.setState({ loading: true })
		axios.delete('/carrito', {
			params: {
				venta_id: this.props.venta_id,
				detalle_id: id
			}
		}).then(({ data }) => {
			message.success("Item deleted")
			if(data.deleteCart === true){
				this.props.onClose()
				message.success("Sale deleted")
			}else this.getVenta()
		}).catch((error) => {
			console.log(error)
			message.error("Error delete")
			this.setState({loading: false})
		})
	}


	/**
	 *
	 *
	 * @memberof TableCompra
	 * @description Este metodo se encarga de calcular las ordenes de compra.
	 */
	onSave = (values) => {
		console.log("values", values);
		this.setState({ loading: true })
		axios.put('/ventas', {
			venta_id: this.props.venta_id,
			...values
		})
		.then(({ data }) => {
			message.success("The sale " + data.folio + " has been updated successfully.")
			this.props.onSave()
		})
		.catch(e => {
			console.log('datadatadata', e)
			message.error("Error updating sale")
		}).finally(()=>this.setState({ loading: false }))
	}

	calculateTotal = () => {
		let { discount } = this.formTableOrdenCompra.current.getFieldsValue()
		console.log("discount", discount);

		let sub_total = 0, total = 0;

		let total_servicios = 0, total_productos = 0

		for(let detalle of this.state.dataSource){
			console.log("detalle", detalle);

			if(detalle.servicio_id){
				total_servicios = Decimal(total_servicios).add(Decimal(detalle.cantidad).mul(detalle.precio).sub(detalle.discount ?? 0)).toNumber() 
			}else if(detalle.variante_id){
				let tax = detalle.variante_id ? Decimal(detalle.cantidad).mul(detalle.precio).mul(0.07).toNumber() : 0
				total_productos = Decimal(total_productos).add(Decimal(detalle.cantidad).mul(detalle.precio).add(tax).sub(detalle.discount ?? 0)).toNumber() 
			}

		}
		
		if(discount.type_discount === "FIXED_AMOUNT"){

			total_servicios = total_servicios > 0 ? Decimal(total_servicios).sub(discount.amount ?? 0).toNumber() : 0

			total = Decimal(total_servicios).add(total_productos).toNumber()
			console.log("total", total);
		}else if(discount.type_discount === "FIXED_PERCENTAGE"){

			total_servicios = total_servicios > 0 ? Decimal(total_servicios).sub(Decimal(discount.amount ?? 0).div(100).mul(total_servicios)).toNumber() : 0

			total = Decimal(total_servicios).add(total_productos).toNumber()
		}

		
		if(total < 0) total = 0

		this.setState({total})
	}

	/**
	 *
	 *
	 * @memberof TableOrdenCompra
	 * @description Permite mostarar y actualizar los valores de la tabla
	 */
	columns = [
		{
			title: 'Product',
			dataIndex: ['variante_id', 'nombre'],
			key: 'name',
			width: "20%",
			render: (value, item) => {
				let producto = item?.variante_id;
				return (
					<Space direction="vertical" size={0}>
						<Text className="product-text-name">
							{producto ? producto.nombre : ""}
						</Text>
					</Space>
				);
			},
		},
		{
			title: 'Service',
			dataIndex: ['servicio_id', 'nombre'],
			key: 'name',
			width: "20%",
			render: (value, item) => {
				let servicio = item?.servicio_id;
				return (
					<Space direction="vertical" size={0}>
						<Text className="product-text-name">
							{servicio ? servicio.nombre : ""}
						</Text>
					</Space>
				);
			},
		},
		{
			title: "Quantity",
			dataIndex: "cantidad",
			key: "cantidad",
			render: (value, record, index) => (
				<span>
					{(this.state.dataSource[index]?.cantidad) ? this.state.dataSource[index]?.cantidad : "0"}
				</span>
			),
		},
		{
			title: "Base price",
			key: "otros",
			render: (value, record, index) => (
				<span>
					$ {(this.state.dataSource[index]?.precio) ? this.state.dataSource[index]?.precio.toMoney(true) : "0"}
				</span>
			),
		},
		{
			title: "Discount",
			key: "discount",
			shouldCellUpdate: () => true,
			render: (value, record, index) => {
				return (
					<span>
						$ {Decimal(value.discount ?? 0).add(value.general_discount ?? 0).toNumber().toMoney(true)}
					</span>
				);
			},
		},
		{
			title: "Net Amount",
			key: "net_amount",
			width: "150px",
			shouldCellUpdate: () => true,
			render: (value, record, index) => {
				return (
					<span>
						$ {(this.state.dataSource[index]?.total) ? this.state.dataSource[index]?.total.toMoney(true) : "0"}
					</span>
				);
			},
		},
		{
			title: "Tax",
			key: "utilidad_total",
			shouldCellUpdate: () => true,
			render: (value, record, index) => {
				return (
					<span>
						$ {(!this.state.dataSource[index]?.servicio_id) ? Decimal(this.state.dataSource[index]?.total).mul(0.07).toNumber().toMoney(true) : "0"}
					</span>
				);
			},
		},
		{
			title: "Actions",
			key: "acciones",
			render: (value, record, index) => {
				return (
					<Space>
						<Popconfirm
                            placement="topRight"
                            title="Do you want to delete this item?"
                            disabled={this.state.disableEdit || !this.props.canEdit} 
                            onConfirm={() => this.deleteItem(record._id)}
                            okText="Yes"
                            cancelText="Cancel"
                        >
							<Button 
								disabled={this.state.disableEdit || !this.props.canEdit} 
								type="primary" 
								size='small' 
								danger 
								icon={<DeleteOutlined />} 
							/>
						</Popconfirm>
						<Button disabled={!record.servicio_id} type="primary" size='small' icon={<EditOutlined />} onClick={() => this.setState({visibleModalAgregarServicios:true ,detalle: record})}></Button>
					</Space>
					)

			},
		},
	];

	formTableOrdenCompra = createRef()

	refTableOrdenCompra = createRef()

	render() {

		const { dataSource, orden, visibleModalAgregarProductos, visibleModalAgregarServicios, visibleModalAgregarEnvio } = this.state
		const { columns, formTableOrdenCompra, refTableOrdenCompra } = this

		let disable_inputs = (this.state.disableEdit || !this.props.canEdit) 

		return (
			<>
				<Spin spinning={this.state.loading}>
					<Form
						ref={formTableOrdenCompra}
						rowKey={record => record._id}
						onFinish={this.onSave}
						labelCol={{ span: 3 }}
    					wrapperCol={{ span: 21 }}
					>
						<Row>
							<Col span={6}>
								<Form.Item

									name="status"
									rules={[{
										required: true,
										message: "Please specify the status of the sale"
									}]}
								>
									<Select options={[0, 6, 1, 2, 3, 4, 5].map(e => ({ value: e, label: renderEstatusVenta(e, true).name }))}
										placeholder="Status"
									/>
								</Form.Item>
							</Col>

						</Row>
						<Row>
							<Col span={24}>
								<Row justify={"center"} className="mb-1">
									<Text>Customer Information</Text>
								</Row>
								<Spin spinning={this.state.loadingClientInfo}>
									<Row gutter={[12, 12]}>
										<Col span={12}>
											<Form.Item
												label="Name"
												name="cliente_id"
												rules={[{
													required: true,
													message: "Please select a client"
												}]}
											>
												<SelectClientes disabled={disable_inputs} enableAdd={true} onSelect={(e) => this.handleClientSelect(e)} />
											</Form.Item>
										</Col>
										<Col xs={12}>
											<Form.Item
												label="Last Name"
												name="apellidos"
												rules={[{
													required: true,
													message: "Input the last name"
												}]}
											>
												<Input disabled={disable_inputs}></Input>
											</Form.Item>
										</Col>
										<Col xs={12}>
											<Form.Item
												label="Email"
												name="email"
												rules={[
			                                        {
			                                            type: 'email',
			                                            message: 'Email is not valid',
			                                        },
			                                        {
			                                            required: true,
			                                            message: 'Enter the email',
			                                        }, {
			                                            max: 50,
			                                            message: "Maximum 50 characters"
			                                        },{
			                                            validator: (_, value) => {
			                                                // Transformar el valor a minúsculas
			                                                const lowercaseValue = value.toLowerCase();
			                                                if (value !== lowercaseValue) this.formTableOrdenCompra.current.setFieldsValue({ email: lowercaseValue });
			                                                return Promise.resolve();
			                                            },
			                                        }
			                                    ]}
											>
												<Input disabled={disable_inputs}></Input>
											</Form.Item>
										</Col>
										<Col xs={12}>
											<Form.Item
												label="Phone"
												name="telefono"
												/*rules={[{
													required: true,
													message: "Input a phone number"
												}]}*/
											>
												<Input disabled={disable_inputs}></Input>
											</Form.Item>
										</Col>
										<Col xs={12}>
											<Form.Item
												label="Zip code"
												name="zip_code"
											// rules={[{
											// 	required: true,
											// 	message: "Input a zip code"
											// }]}
											>
												<Input disabled={disable_inputs}></Input>
											</Form.Item>
										</Col>
										<Col xs={12}>
											<Form.Item
												name="payment_status"
                                            	label="Payment status"
											// rules={[{
											// 	required: true,
											// 	message: "Input a zip code"
											// }]}
											>
												<Select
	                                                disabled={disable_inputs}
	                                                placeholde="Payment Status"
	                                                options={[{
	                                                    value: 1,
	                                                    label: 'Pending'
	                                                },
	                                                {
	                                                    value: 2,
	                                                    label: 'Paid'
	                                                }]}
	                                            />
											</Form.Item>
										</Col>
										<Col xs={12}>
											<Form.Item
												label="Notes"
												name="notes"
											// rules={[{
											// 	required: true,
											// 	message: "Input a zip code"
											// }]}
											>
												<Input.TextArea  /*disabled={disable_inputs}*/ />
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={[12, 12]}>
										<Col xs={12} className="">
											<Form.Item
												label="Discount"
											
											>
												<Space.Compact>
											        <Form.Item
											         	name={['discount', 'type_discount']}
											          	noStyle
											        >
											         	<Select 
											         		placeholder="Select type discount" 
											         		style={{ width: "120%" }}
											         		disabled={this.state.type_discount_editable}
											         		onSelect={(value) =>{
											         			this.setState({type_discount: value},()=>this.calculateTotal())
											         			this.formTableOrdenCompra.current.setFieldsValue({ discount: { type_discount: value, amount: 0 } })
											         		}}
											         	>
											            	<Option value="FIXED_AMOUNT">Amount ($)</Option>
											            	<Option value="FIXED_PERCENTAGE">Percentage (%)</Option>
											          	</Select>
											        </Form.Item>
											        <Form.Item
											          	name={['discount', 'amount']}
											          	noStyle
											        >
											          	<InputNumber 
											          		style={{ width: "120%" }} 
											          		placeholder="Input Amount" 
											          		formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
						                                    min={0}
						                                    max={this.state.type_discount === "FIXED_PERCENTAGE" ? 100 : null}
						                                    onChange={(value)=>this.calculateTotal()}
						                                    disabled={!this.state.type_discount}
											          	/>
											        </Form.Item>
											      </Space.Compact>
											</Form.Item>
										</Col>
										<Col xs={12} className="flex-right">
											<Title level={3} className="m-0">Total: {this.state.total ? this.state.total.toMoney(true) : "0.00" } USD</Title>
										</Col>

									</Row>
								</Spin>


							</Col>
						</Row>


						<Table
							className="table-orden-compra"
							dataSource={dataSource}
							columns={columns}
							pagination={false}
							footer={() =>
								<Row gutter={[12, 0]}>
									<Col xs={8}>
										<Button block disabled={disable_inputs} onClick={() => this.setState({ visibleModalAgregarProductos: true })}>Add an item +</Button>
									</Col>
									<Col xs={8}>
										<Button block disabled={disable_inputs} onClick={() => this.setState({ visibleModalAgregarServicios: true })}>Add a service +</Button>
									</Col>
									<Col xs={8}>
										<Button block disabled={disable_inputs} onClick={() => this.setState({ visibleModalAgregarEnvio: true })}>Add shipping costs +</Button>
									</Col>

								</Row>
							}
						/>
					</Form>
				</Spin>

				<ModalServicios
					visible={visibleModalAgregarServicios}
					onClose={(flag) => {
						this.setState({ visibleModalAgregarServicios: false, detalle: null })
						if (flag === true) {
							this.getVenta()
						}
					}}
					detalle={this.state.detalle}
					venta_id={this.props.venta_id} />

				<ModalShipping
					visible={visibleModalAgregarEnvio}
					onClose={(flag) => {
						this.setState({ visibleModalAgregarEnvio: false })
						if (flag === true) {
							this.getVenta()
						}
					}}
					venta_id={this.props.venta_id} />

				<ModalProductos
					visible={visibleModalAgregarProductos}
					onClose={(flag) => {
						this.setState({ visibleModalAgregarProductos: false })
						if (flag === true) {
							this.getVenta()
						}
					}}
					venta_id={this.props.venta_id}
				/>
			</>
		)
	}
}


export default function (props) {
    const { visible = false, onClose = () => { }, venta_id } = props


    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ['ventas', 'edit_client'],
    })

    return <TableOrdenCompra ref={props.forwardedRef} {...props} {...permisos} />

}